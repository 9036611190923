#{$templateWrapperDeclaration} .card {
  &.processing-card {
    margin-bottom: 1.25rem;
    &.processing-card-forms-holder {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;
    }
  }

  h3.custom-header {
    width: 100%;
    padding: 0.265rem 0.265rem 0.5rem 0.265rem;
    border-bottom: 0.0625rem solid #ccc;
    font-size: 1.125rem;
    font-weight: normal;
    margin-bottom: 1rem;
    > span {
      font-size: 0.875rem;
      font-weight: normal;
    }
  }

  table.order-summary-table {
    border-top: 1px solid #ccc;
    td,
    th {
      border-left: 0.0625rem solid #ccc;
      vertical-align: top;
      &:last-child {
        border-right: 0.0625rem solid #ccc;
      }
      &.table-pricing {
        text-align: right;
        padding-right: 0.5rem;
      }
    }
  }

  .payment-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .terms-of-service-scroller {
    max-height: 10rem;
    overflow: auto;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: $mediaBreakPointSmall) {
  #{$templateWrapperDeclaration} .card {
    &.processing-card.processing-card-forms-holder {
      display: block;

      .form-checkbox-billing {
        margin-bottom: 1.5rem;
      }
    }

    .payment-wrapper {
      display: block;
      .options {
        border-top: 0.0625rem solid #eee;
        border-bottom: 0.0625rem solid #eee;
        margin: 0.5rem 0;
      }
    }
  }
}
