#{$templateWrapperDeclaration} .storefront-primary-menu-container {
  // display: none;
  width: 100%;
  height: 3.75rem;
  background-color: #f1f1f0;
  border-top: 0.0625rem solid #e1e1e1;
  border-bottom: 0.0625rem solid #e1e1e1;
  > .storefront-primary-menu-brand {
    display: none;
  }
}

#{$templateWrapperDeclaration}
  .storefront-primary-menu-container
  > .storefront-primary-menu {
  max-width: $mainContentWidth;
  margin: 0 auto;
  > ul {
    list-style: none;
    z-index: 500;
    > li {
      z-index: 500;
      position: relative;
      display: inline;
      border-right: 0.0625rem solid #e9e9e9;
      line-height: 3.4375rem;
      padding: 0.625rem 0.75rem;
      font-size: 0.75rem;
      text-transform: uppercase;
      cursor: pointer;
      &:last-child {
        border: none;
      }

      &:hover > ul {
        display: block;
      }

      > ul {
        z-index: 100;
        display: none;
        min-width: 100%;
        position: absolute;
        top: 2.25rem;
        left: 0;
        border: 0.0625rem solid #e1e1e1;
        > li {
          min-width: 12rem;
          background-color: #efefef;
          border-bottom: 0.0625rem solid #e1e1e1;
          line-height: 1.5rem;
          padding: 0.25rem 0.25rem 0.25rem 0.5rem;
          font-size: 0.75rem;
          text-transform: uppercase;
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaBreakPointMedium) {
  #{$templateWrapperDeclaration}
    .storefront-primary-menu-container
    > .storefront-primary-menu {
    color: #000;
  }
}

@media only screen and (max-width: $mediaBreakPointSmall) {
  #{$templateWrapperDeclaration} .storefront-primary-menu-container {
    height: auto;
    width: 100%;
    .storefront-primary-menu-brand {
      width: 100%;
      display: grid;
      grid-template-columns: auto 20%;
      padding: 0.5rem;
      border-bottom: 0.0625rem solid #e9e9e9;
      > .name {
      }
      > .burger {
        text-align: right;
      }
    }
  }

  #{$templateWrapperDeclaration}
    .storefront-primary-menu-container
    > .storefront-primary-menu {
    margin: 0 auto;
    > ul.storefront-primary-menu-ul {
      list-style: none;
      display: none;
      &.primary-menu-open {
        position: absolute;
        width: 100%;
        display: block;
        background-color: #f5f5f5;
      }
      > li {
        display: block;
        padding: 0.25rem 0.25rem 0.25rem 0.5rem;
        line-height: 1.5rem;
        border-bottom: 0.0625rem solid #e9e9e9;
        > ul {
          display: none;
          position: relative;
          top: auto;
          left: auto;
          padding-top: 0.25rem;
          > li {
            font-size: 0.75rem;
            line-height: 1.5rem;
            text-transform: none;
            padding-left: 0.75rem;
            background-color: transparent;
            &:last-child {
              border: none;
            }
          }
        }
      }
    }
  }
}
