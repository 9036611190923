#{$templateWrapperDeclaration} {
  .form-input,
  .form-select {
    height: auto;
    border-radius: 0.25rem;
    padding: 0.25rem 0.375rem;
    &.input-md {
      padding: 0.5rem;
    }
  }

  .form-label-text-align {
    text-align: right;
    padding-right: 1rem;
  }
}

@media only screen and (max-width: $size-sm) {
  #{$templateWrapperDeclaration} {
    .form-label-text-align {
      text-align: left;
      padding-right: 0;
    }
  }
}
