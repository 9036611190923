.design-upload-dropzone-pad {
  cursor: pointer;
  width: 100%;
  height: 5rem;
  margin-bottom: 0.625rem;
  border: 0.0625rem solid $border-color1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.design-upload-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.5rem;
  text-align: center;
  .design-image-holder {
    border: 0.0625rem solid $border-color1;
    padding: 0.375rem;
    > .image {
      border: 0.0625rem solid $border-color1;
    }
    > .info {
      padding: 0.5rem 0;
    }
  }
}

@media only screen and (max-width: $mediaBreakPointSmall) {
  #{$templateWrapperDeclaration} {
    .design-upload-dropzone-pad {
      height: 3rem;
    }

    .design-upload-wrapper {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
