// #{$templateWrapperDeclaration} .card {
//   transition: 0.3s;
//   width: 100%;
//   padding: 0.5rem;
//   border-radius: 0.375rem;
//   border: 0.0625rem solid #e3e3e3;
//   background-color: #f5f5f5;
//   &:hover {
//     box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
//   }
//   &.container {
//     padding: 0.125rem 1rem;
//   }
// }

#{$templateWrapperDeclaration} .card {
  padding: 0.5rem;
  background-color: #f5f5f5;
}
