#{$templateWrapperDeclaration} .storefront-order-wrapper {
  display: grid;
  grid-template-columns: 3fr minmax(18rem, 2fr);
  grid-gap: 2.5rem;
  > .storefront-order-info-wrapper {
    // display: grid;
    // grid-template-rows: 1fr;
    // grid-row-gap: 1.25rem;
  }
  > .form-holder {
  }
}

@media only screen and (max-width: $mediaBreakPointSmall) {
  #{$templateWrapperDeclaration} .storefront-order-wrapper {
    grid-template-columns: 1fr;
    > .form-holder {
      // margin-top: 1.25rem;
    }
  }
}
