#{$templateWrapperDeclaration} .storefront-footer-wrapper {
  width: 100%;
  margin-top: 1.25rem;
  border-top: 0.25rem solid #555;
  background-color: #262626;
}

#{$templateWrapperDeclaration} .storefront-primary-footer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
  max-width: $mainContentWidth;
  margin: 0 auto;
  padding: 0 0.25rem;
  > div {
    padding: 1rem 0.5rem;
    > ul {
      list-style: none;
    }
  }
}

#{$templateWrapperDeclaration} .storefront-primary-footer > div > ul > li {
  font-size: 0.75rem;
  color: #fff;
  padding: 0.375rem 0;
  &.header {
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 0.0625rem solid #555;
    &.mid-header {
      padding-top: 0.5rem;
    }
  }
  a {
    color: #fff;
    &:hover {
      color: $accent-color;
    }
  }
}

@media only screen and (max-width: $mediaBreakPointMedium) {
  #{$templateWrapperDeclaration} .storefront-primary-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: $mediaBreakPointSmall) {
  #{$templateWrapperDeclaration} .storefront-primary-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
