.material-icons {
  font-size: 0.75rem;
  // color: $primary-color;

  &.md-18 {
    font-size: 1.125rem;
  }

  &.md-24 {
    font-size: 1.5rem;
  }

  &.md-36 {
    font-size: 2rem;
  }

  &.md-48 {
    font-size: 3rem;
  }

  &.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }

  &.md-light {
    color: rgba(255, 255, 255, 1);
  }

  &.md-active {
  }

  &.md-inactive {
  }
}
