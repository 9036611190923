*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.clear-fix {
  height: 0;
  line-height: 0;
  clear: both;
}

html {
  font-size: 16px;
}

body {
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

ul {
  list-style: none;
}
