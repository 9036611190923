#{$templateWrapperDeclaration}
  .storefront-main-content-wrapper
  .storefront-page-content {
  padding: 0 0.75rem 0 0.375rem;
  width: 100%;
  .storefront-page-content-header {
    @include defaultPageHeader;
    text-indent: 0.3125rem;
    padding: 0.75rem 0.625rem 0.625rem 0;
    margin-bottom: 1rem;
  }
}
