@mixin topHeaderUl() {
  ul {
    list-style: none;
    margin-top: 0.6875rem;
    overflow: hidden;
    height: 2.125rem;
    li {
      display: inline-flex;
      align-items: center;

      border-right: 0.0625rem solid $border-color1;
      line-height: 2.125rem;
      padding: 0 0.75rem;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border: none;
      }
      a {
        color: #69686c;
      }
      a:hover {
        color: $accent-color;
        text-decoration: none;
      }
      span.icon {
        padding-right: 0.25rem;
      }
    }
  }
}

#{$templateWrapperDeclaration} .storefront-top-header-wrapper {
  height: 3.5rem;
  border-bottom: 0.0625rem solid $border-color2;
  background-color: #fff;

  .storefront-top-header {
    max-width: $mainContentWidth;
    display: grid;
    grid-template-columns: auto auto;
    font-size: 0.875rem;
    margin: 0 auto;
    > div {
      padding: 0 0.75rem;
    }
    .top-header-contact-information {
      text-align: left;
      @include topHeaderUl();
    }

    .top-header-menu {
      text-align: right;
      @include topHeaderUl();
    }
  }
}

@media only screen and (max-width: $mediaBreakPointMedium) {
  #{$templateWrapperDeclaration} .storefront-top-header {
    width: 100%;
  }
}

@media only screen and (max-width: $mediaBreakPointSmall) {
  #{$templateWrapperDeclaration} .storefront-top-header-wrapper {
    height: 2.5rem;
    .storefront-top-header {
      display: inline;
      .top-header-contact-information {
        display: none;
      }
      .top-header-menu {
        text-align: center;
        ul {
          margin-top: 0;
          padding-top: 0.15rem;
        }
      }
    }
  }
}
