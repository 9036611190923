#{$templateWrapperDeclaration} .storefront-secondary-footer {
  color: #ddd;
  font-size: 0.75rem;
  text-align: center;
  background-color: #262626;
  padding: 1rem 0 0.75rem;
  line-height: 1rem;
  border-top: 0.0625rem solid #555;
  a {
    color: #ddd;
    &:hover {
      color: $accent-color;
    }
  }
}
