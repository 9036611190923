// Button variant mixin
@mixin button-variant($color: $primary-color) {
  background: $color;
  border-color: darken($color, 3%);
  color: $light-color;
  &:focus {
    @include control-shadow($color);
  }
  &:focus,
  &:hover {
    background: darken($color, 2%);
    border-color: darken($color, 5%);
    color: $light-color;
  }
  &:active,
  &.active {
    background: darken($color, 7%);
    border-color: darken($color, 10%);
    color: $light-color;
  }
  &.loading {
    &::after {
      border-bottom-color: $light-color;
      border-left-color: $light-color;
    }
  }
}

@mixin button-variant-light($color: $primary-color) {
  background: $color;
  border-color: darken($color, 30%);
  color: $dark-color;
  &:focus {
    @include control-shadow($color);
  }
  &:focus,
  &:hover {
    background: darken($color, 3%);
    border-color: darken($color, 30%);
    color: $dark-color;
  }
  &:active,
  &.active {
    background: darken($color, 3%);
    border-color: darken($color, 50%);
    color: $dark-color;
  }
  &.loading {
    &::after {
      border-bottom-color: $dark-color;
      border-left-color: $dark-color;
    }
  }
}

@mixin button-outline-variant($color: $primary-color) {
  background: $light-color;
  border-color: $color;
  color: $color;
  &:focus {
    @include control-shadow($color);
  }
  &:focus,
  &:hover {
    background: lighten($color, 50%);
    border-color: darken($color, 2%);
    color: $color;
  }
  &:active,
  &.active {
    background: $color;
    border-color: darken($color, 5%);
    color: $light-color;
  }
  &.loading {
    &::after {
      border-bottom-color: $color;
      border-left-color: $color;
    }
  }
}
